/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import { sessionStorage } from "../../../../../assets/myjs/glovar.js";
import { Dialog } from "vant";
export default {
  name: "ywNoteFrom",
  data() {
    return {
      ywInfoData: {},
      showData: false,
      gzdbh: "",
      xmbh: "",
      pzry: "",
      pzrq: "",
      pznr: ""
    };
  },
  created() {
    this.xmbh = sessionStorage.getItem("xmbh");
  },
  methods: {
    onClickLeft() {
      this.$router.push("/ywTrack");
      sessionStorage.removeItem("xmbh");
    },
    onConfirm(timestamp) {
      let year = timestamp.getFullYear();
      const month = (timestamp.getMonth() + 1).toString().padStart(2, "0");
      const date = timestamp.getDate().toString().padStart(2, "0");
      this.pzrq = year + "-" + month + "-" + date;
      this.showData = false;
    },
    onSubmit() {
      // var params = new FormData();
      var params = {
        xmbh: this.xmbh,
        pzry: this.pzry,
        pzrq: this.pzrq,
        pznr: this.pznr
      };
      // params.append("xmbh", this.xmbh);
      // params.append("pzry", this.pzry);
      // params.append("pzrq", this.pzrq);
      // params.append("pznr", this.pznr);
      var url = _baseUrl + `/ywgz/gzjlpz/gzJlPz/save.do`;
      axios.post(url, params).then(res => {
        if (res.data.success) {
          Dialog.alert({
            title: "提示",
            message: res.data.message
          }).then(() => {
            this.$router.push("/ywTrack");
            sessionStorage.removeItem("xmbh");
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "网络错误！"
          });
        }
      });
    }
  }
};